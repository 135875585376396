var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { id: "invoices" } },
    [
      _c(
        "masthead",
        {
          attrs: {
            id: "test",
            title: "Invoices",
            datasource: _vm.invoices,
            "filter-options": _vm.filterOptions,
          },
          on: { changed: _vm.searchChanged },
        },
        [
          _c(
            "b-button",
            {
              staticClass: "mr-3",
              attrs: {
                variant: "primary",
                size: "$mq",
                disabled: _vm.payBtnDisabled,
              },
              on: { click: _vm.payClicked },
            },
            [
              _c("feather-icon", { attrs: { type: "dollar-sign" } }),
              this.$mq !== "sm"
                ? _c("span", [_vm._v(_vm._s(_vm.payBtnText))])
                : _vm._e(),
              this.$mq === "sm" ? _c("span", [_vm._v("Pay")]) : _vm._e(),
            ],
            1
          ),
          _c(
            "b-button",
            {
              attrs: { variant: "primary", disabled: _vm.downloadBtnDisabled },
              on: { click: _vm.downloadClicked },
            },
            [
              _c("feather-icon", { attrs: { type: "download" } }),
              _vm._v("\n      " + _vm._s(_vm.downloadBtnText) + "\n    "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-tabs",
        {
          model: {
            value: _vm.tabIndex,
            callback: function ($$v) {
              _vm.tabIndex = $$v
            },
            expression: "tabIndex",
          },
        },
        [
          _c(
            "b-tab",
            [
              _c("template", { slot: "title" }, [
                _vm._v("\n        My Invoices\n      "),
              ]),
              _c("ajax-table", {
                ref: "invoicesTable",
                attrs: {
                  "select-mode": "multi",
                  selectable: true,
                  "table-definition": _vm.myInvoices,
                  "no-local-sorting": true,
                },
                on: {
                  "selection-changed": _vm.selectionEvent,
                  loaded: _vm.onLoad,
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(states)",
                    fn: function (data) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.stateListFormatted(data.item).join(", ")
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(content)",
                    fn: function (data) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getContentNames(data.item).join(", ")) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(actions)",
                    fn: function (row) {
                      return [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.view(row.item)
                              },
                            },
                          },
                          [_vm._v("\n            View\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c(
            "b-tab",
            [
              _c("template", { slot: "title" }, [
                _vm._v("\n        Invoices Shared With Me\n      "),
              ]),
              _c("ajax-table", {
                ref: "invoicesSharedTable",
                attrs: {
                  "select-mode": "multi",
                  selectable: true,
                  "table-definition": _vm.invoiceSharedWithMe,
                  "no-local-sorting": true,
                },
                on: {
                  "selection-changed": _vm.selectionEvent,
                  loaded: _vm.onSharedLoad,
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(actions)",
                    fn: function (row) {
                      return [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.view(row.item)
                              },
                            },
                          },
                          [_vm._v("\n            View\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }